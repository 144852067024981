/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Ubuntu-Regular;
  src: url("ubuntu/Ubuntu-Regular.ttf");
}

@font-face {
  font-family: Ubuntu-Bold;
  src: url("ubuntu/Ubuntu-Bold.ttf");
}

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/*---------------------------------------------*/

/*---------------------------------------------*/

/*---------------------------------------------*/
.login-form {
  font-family: Ubuntu-Regular, sans-serif;
  letter-spacing: initial;
  line-height: initial;
}
.login-form input {
  outline: none;
  border: none;
}

.login-form input:focus {
  border-color: transparent !important;
}

.login-form input:focus::-webkit-input-placeholder {
  color: transparent;
}
.login-form input:focus:-moz-placeholder {
  color: transparent;
}
.login-form input:focus::-moz-placeholder {
  color: transparent;
}
.login-form input:focus:-ms-input-placeholder {
  color: transparent;
}

.login-form input::-webkit-input-placeholder {
  color: #555555;
  opacity: 1 !important;
}
.login-form input:-moz-placeholder {
  color: #555555;
}
.login-form input::-moz-placeholder {
  color: #555555;
}
.login-form input:-ms-input-placeholder {
  color: #555555;
}

.login-form label {
  margin: 0;
  display: block;
}

/*---------------------------------------------*/
.login-form button {
  outline: none !important;
  border: none;
}

.login-form button:hover {
  cursor: pointer;
}

/*//////////////////////////////////////////////////////////////////
[ login ]*/

.login-form {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-image: url("gift.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  background: -webkit-radial-gradient(circle, #c66870, #ffffff);

  background: -o-radial-gradient(circle, #c66870, #ffffff);
  background: -moz-radial-gradient(circle, #c66870, #ffffff);
  background: radial-gradient(circle, #c66870, #ffffff);
  opacity: 0.4;
}

.wrap-login100 {
  width: 390px;
  border-radius: 10px;
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 50px;
  background: transparent;
}

/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  padding-bottom: 33px;
  padding-top: 5px;
}

.login100-form-title {
  font-family: Ubuntu-Bold;
  font-size: 28px;
  color: #fff;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 41px;
  display: block;
}

/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #e6e6e6;
  padding: 29px 0;
  margin-bottom: 2px;
}
.wrap-input100 a {
  position: absolute;
  right: 10px;
  color: #ce202f;
  text-decoration: underline;
  bottom: -20px;
}
.input100 {
  font-family: Ubuntu-Regular;
  font-size: 20px;
  color: #555555;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 50px;
  background: transparent;
  padding: 0 10px 0 80px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 1px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #ce202f;
  background: -webkit-linear-gradient(to right, #7c111a, #ce202f 70%);
  background: -o-linear-gradient(to right, #7c111a, #ce202f 70%);
  background: -moz-linear-gradient(to right, #7c111a, #ce202f 70%);
  background: linear-gradient(to right, #7c111a, #ce202f 70%);
}

.focus-input100::after {
  font-family: "FontAwesome";
  font-size: 27px;
  color: #999999;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 39px;
  left: 35px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus {
  padding-left: 60px;
}

.input100:focus + .focus-input100::after {
  left: 23px;
  color: #ce202f;
}

.alert-validate.input-focus:before {
  visibility: hidden !important;

  opacity: 0 !important;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::after {
  left: 23px;
  color: #ce202f;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.has-val.input100 {
  padding-left: 60px;
  padding-right: 35px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  margin-top: 32px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  font-family: Ubuntu-Bold;
  font-size: 18px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 160px;
  height: 42px;
  border-radius: 21px;

  background: #ce202f;
  background: -webkit-linear-gradient(to right, #7c111a, #ce202f 70%);
  background: -o-linear-gradient(to right, #7c111a, #ce202f 70%);
  background: -moz-linear-gradient(to right, #7c111a, #ce202f 70%);
  background: linear-gradient(to right, #7c111a, #ce202f 70%);
  position: relative;
  z-index: 1;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 21px;
  background-color: #555555;
  top: 0;
  left: 0;
  opacity: 0;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background-color: transparent;
}

.login100-form-btn:hover:before {
  opacity: 1;
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  pointer-events: none;

  font-family: Ubuntu-Bold;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
  animation: blinker 1.5s linear infinite;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  font-size: 16px;
  color: #c80000;

  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
}

.alert-validate:hover:before {
  visibility: visible;

  opacity: 1;
}

@keyframes blinker {
  50% {
    opacity: 1;
  }
}

.bar-loader {
  position: fixed;
  z-index: 111111111111;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffffa6;
}

.bar-loader svg {
  width: 75px;
}

.login-logo {
  width: 100%;
  display: block;
  margin: 0 auto;
  /* -webkit-filter: contrast(0) brightness(10000000);
  filter: contrast(0) brightness(10000000); */
  margin-bottom: 35px;
  filter: drop-shadow(2px 4px 6px #86131d57);
}
