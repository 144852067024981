.img-bg body {
  font-size: 14px;
  color: #716e6e;
  font-family: "Roboto", sans-serif !important;
  font-weight: 300;
  line-height: 22px;
  padding: 0;
  letter-spacing: inherit;
  margin: 0;
}

.white-screen {
  position: fixed;
  background: white;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
}

.img-bg .error-span {
  color: red;
  margin-top: 5px;
  display: block;
  font-size: 16px;
}
.img-bg .page-link {
  color: #3e3934 !important;
}
.img-bg .page-link:focus,
.img-bg .active > .page-link,
.img-bg .page-link.active {
  background-color: #ce202f !important;
  border-color: #ce202f !important;
  color: white !important;
}

.img-bg .make-center {
  display: flex;
  min-height: 100vh;
  align-items: center;
  background: linear-gradient(
    180deg,
    #ca1f2e1a 0,
    #fff 17%,
    #fff 52%,
    #fff 83%,
    #ca1f2e1a
  );
  background-size: cover !important;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.img-bg .container.home-new {
  max-width: 1140px;
  height: 432px;
  box-shadow: 0 0 15px 0 #d4d4d440;
}
.img-bg .logo-sec {
  float: left;
  width: 50%;
  height: 100%;
  padding: 20px 25px;
  background-color: #fff;
}
.img-bg img.img-fluid.home-logo {
  margin: -20px auto;
  display: block;
  max-width: 1125px;
  width: 1125px;
  filter: drop-shadow(2px 4px 6px #ca1f2e1a);
  margin-bottom: 0px;
}
.img-bg .read-head {
  font-weight: 800;
  font-size: 16px;
  color: #ce202f;
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 0px;
  line-height: 1.4;
}
.img-bg .instruction-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.img-bg .instruction-list li {
  position: relative;
  margin-top: 20px;
  color: #222222;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.img-bg .instruction-list li img {
  width: 45px;
  margin-right: 15px;
}
.img-bg .form-sec {
  float: left;
  width: 50%;
  padding: 30px 45px;

  height: 100%;
  background-color: #3e3934;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.img-bg .white-sec {
  display: flex;
  background: white;
  margin-left: -45px;
  width: calc(100% + 65px);
  align-items: center;
  padding: 15px 20px;
}
.g-recaptcha {
  margin-top: 30px;
}
.img-bg .white-sec img {
  width: 65px;
  margin-right: 20px;
}
.img-bg .white-sec span {
  color: #000022;
  font-size: 16px;
  font-weight: 500;
}
.img-bg .voucher-entery {
  margin-top: 30px;
}
.img-bg .voucher-entery label {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}
.img-bg .voucher-entery input.form-control {
  height: 55px;
  box-shadow: none;
  border: 0;
  border-radius: 100px !important;
  font-weight: 500;
  font-size: 17px;
}
.img-bg .voucher-entery button.btn {
  height: 55px;
  box-shadow: none;
  border: 0;
  background: #ce202f;
  width: 120px;
  border-radius: 100px !important;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
}
.img-bg .voucher-entery .form-check {
  margin-top: 25px;
  margin-bottom: 15px;
}
.img-bg .voucher-entery .form-check label {
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 0;
}
.img-bg .voucher-entery .infor {
  margin-top: 15px;
  display: block;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}
.img-bg .copyright-text {
  margin: 0;
  margin-top: 10px;
  text-align: center;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

.img-bg .header-outer img {
  width: 267px;
  margin-top: -20px;
  margin-bottom: -30px;
  display: block;
}
.img-bg .container {
  max-width: 1170px;
}
.img-bg .header-outer .navbar-nav > li > a {
  font-size: 14px;
  font-weight: 300;
  padding: 38px 15px !important;
  font-family: "Roboto", sans-serif !important;
  text-transform: uppercase;
  transition: ease-in-out 0.3s;
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
  cursor: pointer;
}
.img-bg .header-outer .navbar-nav > li:first-child > a {
  padding-left: 0 !important;
}
.img-bg .header-outer .navbar-nav > li:last-child > a {
  padding-right: 0 !important;
}
.img-bg .navbar-nav > li > .dropdown-menu {
  border: 0;
  border-radius: 0;
  border-top: 4px solid #ce202f;
  margin-top: 0;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.img-bg .dropdown-menu > li > a {
  padding: 6px 20px !important;
  font-size: 14px;
}
.img-bg .dropdown-menu > li > a:focus,
.img-bg .dropdown-menu > li > a:hover {
  color: #262626 !important;
  text-decoration: none !important;
  background-color: #f5f5f5 !important;
}

.breadcrumb-wrap {
  background: #f6f6f6;
}
.user-info {
  padding: 14px 0 14px 0px;
}
.cart,
.points,
.welcome {
  position: relative;
  display: block;
  padding-left: 50px;
  color: #000;
}

.cart:before,
.points:before,
.welcome:before {
  font-family: fontAwesome;
  position: absolute;
  height: 30px;
  width: 30px;
  border: 1px solid #ce202f;
  left: 0;
  top: -5px;
  text-align: center;
  background: #ffffff;
  line-height: 27px;
  color: #ce202f;
  font-size: 16px;
}
.welcome:before {
  content: "\f007";
}
.points.expiry-date:before {
  content: "\f073";
}
.cart:before {
  content: "\f07a";
}
.banner-img {
  filter: drop-shadow(2px 4px 6px black);
  display: block;
  margin: 0 auto;
}
.outer-bg {
  /* background: #ce202f;
  background-image: url(https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1691045835/SYG_Banner_saqcdp.jpg); */
}

.header-outer {
  background: #fff;
  width: 100%;
}

.main-content {
  padding: 30px 0 0;
  background: #f9f9f9;
}

.l-search {
  background: #fff;
  margin-bottom: 40px;
}
.l-search h3,
.user-form h3 {
  display: block;
  background: #ce202f;
  color: #fff;
  padding: 13.5px;
  font-size: 20px;
  margin: 0;
}
.s-out {
  padding: 15px;
}
.l-search input[type="text"] {
  width: 60% !important;
  margin-bottom: 0px;
  padding-left: 10px;
  outline: none;
  height: 35px;
  font-size: 13px;
  border: 1px solid #d9d9d9;
  transition: ease-in-out 0.3s;
  font-style: italic;
  float: left;
  margin-right: 2%;
}
.img-bg .l-search select {
  font-size: 13px;
  border: 1px solid #d9d9d9;
  padding-left: 10px;
}
.l-search input[type="text"]::placeholder {
  color: black;
  opacity: 0.7 !important;
}
.l-search input[type="text"]::-ms-input-placeholder {
  color: black;
  opacity: 0.7 !important;
}

.l-search input[type="submit"],
.l-search input[type="reset"] {
  width: 18%;
  margin-bottom: 0;
}
.button,
.login-inner input {
  width: 100%;
  margin-bottom: 15px;
  height: 35px;
  outline: none;
}
.l-search input[type="reset"]:hover,
.button,
.login-inner input[type="submit"] {
  background-color: #ce202f;
  color: #fff;
  font-family: "Roboto", sans-serif !important;
  border: 1px solid #ce202f !important;
  transition: ease-in-out 0.3s;
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
  padding-left: 0;
  font-style: normal !important;
  font-size: 14px !important;
}
.button:hover,
.l-search input[type="reset"],
.login-inner input[type="submit"]:hover {
  background-color: #3e3934;
  color: #fff;
  border: 1px solid #3e3934 !important;
}
.new-heading {
  background: #fff;
  border-top: 3px solid #ce202f;
  margin-bottom: 15px;
}
.new-heading h1 {
  padding: 10px 20px;
  text-transform: uppercase;
  color: #000000;
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 10px;
}
.new-heading h1 img {
  height: 28px;
  width: 28px;
  float: left;
  padding: 0;
  margin-right: 30px;
  vertical-align: middle;
}
.cont-text {
  background: #fff;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}
.cont-text p {
  color: #ff0000;
  font-weight: 500;
  margin-bottom: 0px;
}
.user-form h3 {
  margin-bottom: 20px;
}
.product-wrap {
  border: 1px solid #efefef;
  padding: 0;
  background: #fff;
  position: relative;
  margin-bottom: 30px;
}
.product-img {
  position: relative;
  height: 250px;
  overflow: hidden;
}

.product-img img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-height: 190px;
  max-width: 220px;
  transition: ease-in-out 5s;
  -webkit-transition: ease-in-out 5s;
  -moz-transition: ease-in-out 5s;
}
.controls {
  bottom: 0;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
}
.controls,
.product-overlay {
  position: absolute;
  width: 100%;
  transition: ease-in-out 0.3s;
  text-align: center;
}
.controls a,
.controls button {
  display: inline-flex;
  height: 35px;
  width: 35px;
  background: #ce202f;
  text-align: center;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  border: 0;
  outline: none;
  line-height: 0;
  vertical-align: text-bottom;
  align-items: center;
  justify-content: center;
  margin: 0px 2px;
}
.controls a:hover,
.controls button:hover {
  background: #3e3934;
}
.product-name {
  text-align: center;
  color: #696969;
  margin: 15px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.price {
  display: block;
  color: #ce202f;
  font-weight: 700;
  font-style: initial;
  margin-top: 7px;
}

footer.login-footer {
  text-align: center;
  position: absolute;
  padding: 13px 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #ce202f;
  color: #fff;
  font-weight: 400;
  line-height: normal;
}
footer.login-footer p {
  margin-bottom: 0px;
}
footer.login-footer p a {
  color: #fff;
}
footer.login-footer p a:hover {
  text-decoration: underline;
}
.reward-body {
  min-height: 100vh;
  width: 100%;
  padding-bottom: 100px;
  position: relative;
}

.back-2-top {
  cursor: pointer;
  position: fixed;
  background: #8492a6;
  padding: 7px 10px;
  font-size: 14px;
  float: right;
  border: 1px solid #8492a6;
  margin-left: 10px;
  bottom: 20px;
  right: 20px;
  z-index: 99999;
  display: none;
}
.back-2-top.showbtn {
  display: block;
}

.ul-outer {
  position: fixed;
  left: 0px;
  z-index: 11;
  top: 50%;
  margin-top: -60px;
}
ul.ul-inner {
  margin-top: 0;
  margin-bottom: 10px;
}
ul.ul-inner li {
  list-style: none;
}
ul.ul-inner li:nth-child(1) a {
  position: absolute;
  background: #3e3934;
  padding: 9px 0px 10px 22px;
  color: #fff;
  font-size: 15px;
  left: -116px;
  text-decoration: none !important;
  width: 159px;
  height: 40px;
  transition: ease-in-out 0.5s;
}
ul.ul-inner li:nth-child(1) i {
  margin-left: 14px;
}
ul.ul-inner li:nth-child(1) a:hover {
  left: 0px;
  text-decoration: none !important;
}
.l-search form {
  margin: 0px 0px 0px 0px;
}
.img-bg .header-outer .navbar-brand {
  color: #000;
  transition: ease-in-out 0.3s;
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
  padding: 5px;
  margin: 0;
}
.img-bg .header-outer .navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  right: 0;
  left: auto;
  padding: 5px 0;
}

/* cart page css here   */
.img-bg .table-shop {
  width: 100%;
  text-transform: none;
  margin-bottom: 25px;
  background: #fff;
  margin-top: 15px;
  line-height: normal;
}
.img-bg .table-shop > thead > tr > th {
  border: none;
  text-align: left;
  text-transform: uppercase;
  padding: 10px 10px 5px 10px;
  font-weight: bold;
  color: #424242;
  border-top: 2px solid #e9e9e9;
}

.img-bg .table-shop .product-no {
  width: 12%;
}

.img-bg .table-shop .product-name {
  width: 40%;
}
.img-bg .table-shop > tbody > tr > td {
  border: 1px solid #cacaca;
  border-top: 2px solid #e9e9e9;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid #e9e9e9;
  padding: 10px;
  vertical-align: top;
  font-size: medium;
  font-style: normal;
  font-weight: 400;
}
.img-bg table.table-shop a {
  color: #f70000;
  transition: ease-in-out 0.5s;
  text-decoration: none;
  font-weight: 400;
}
.img-bg .table-shop img {
  width: 100%;
  min-width: 60px;
  max-width: 60px;
  text-align: center;
}
.img-bg table.table-shop a i.fa.fa-trash-o.rect {
  border: 1px solid red;
  padding: 5px;
  margin-right: 5px;
  border-radius: 0px;
  color: red;
  transition: ease-in-out 0.5s;
}
.img-bg table.table-shop a:hover i {
  background: #f00;
  color: #fff !important;
}
.img-bg .agree a {
  outline: none;
}
.img-bg a.black {
  color: #000 !important;
  font-weight: bold !important;
}
.img-bg .agree label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
.img-bg .agree input {
  width: 15px;
  height: 15px;
  outline: none;
  vertical-align: text-top;
  margin: 0 10px 0 0;
}

.term-model .modal-header {
  background: #ce202f;
}

.term-model .modal-header h2 {
  color: #fff;
  margin: 0;
  line-height: 1.42857143;
  font-weight: 700;
  font-size: 18px;
}
.term-model .modal-header .close {
  margin-top: -2px !important;
  background: transparent;
  border: 0;
  outline: none;
  font-size: 22px;
  margin-top: 3px;
  color: #fff;
  opacity: 0.8;
}
.term-model .modal-dialog.modal-lg {
  max-width: 900px;
}
.term-model .modal-body p,
.term-model .modal-body ul {
  line-height: 1.6;
  box-sizing: border-box;
  margin-bottom: 16px;
  color: rgb(51, 51, 51);
  font-weight: 400;
}
.term-model .modal-body p a {
  color: blue;
}
.term-model .modal-content {
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.term-model .form-floating > label {
  font-weight: 500;
}
.img-bg .react-select__menu,
.img-bg .css-1dimb5e-singleValue {
  color: black !important;
}
.img-bg .header-outer .navbar-nav > li > a {
  color: var(--bs-navbar-active-color);
}

.img-bg .text-left {
  margin-bottom: 15px;
  background: #c1ff9a;
  padding: 10px;
  width: 100%;
  display: block;
  font-weight: 700;
  border-radius: 11px;
  font-size: 15px;
}
.img-bg .m-ship {
  background: #fff;
  padding: 15px;
  font-weight: 400;
}
.img-bg .m-ship h2 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 700;
  font-size: 18px;
}

.img-bg .m-ship textarea {
  padding: 7px 10px;
  height: 100px;
  resize: vertical;
}
.img-bg .product-detail-img {
  margin: 0 0 3px;
  background: #ffffff;
  border: 4px solid #ffffff;
  position: relative;
  zoom: 1;
  -webkit-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
}
.img-bg .product-detail-img img {
  height: auto;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 414px;
  object-fit: contain;
}
.img-bg .desc-product {
  background: #fff;
  padding: 15px;
}
.img-bg .desc-product h2 {
  font-weight: 700;
  font-size: 18px;
  color: #4d4f53;
}
.img-bg .desc-text {
  min-height: 160px;
  margin-top: 16px;
  padding: 0 0 10px;
  margin-bottom: 7px;
  overflow-y: auto;
  height: 160px;
}

.img-bg .desc-text p {
  margin-bottom: 5px;
  color: #696969;
}
.img-bg .sect {
  border-top: 1px solid #ddd;
  padding: 20px 0 10px;
}
.img-bg .sect h4 {
  font-size: 13px;
  font-weight: 700;
  color: #000000;
}
.img-bg .review-sub textarea {
  height: 150px;
  resize: vertical;
  outline: none;
  font-size: 13px;
  margin-bottom: 10px;
  width: 100%;
  padding: 7px 10px;
  border: 1px solid #d9d9d9;
  transition: ease-in-out 0.3s;
  font-style: italic;
}
.img-bg .user-form textarea:focus {
  border: 1px solid #ce202f;
}
.img-bg .review-portion {
  background: #fff;
  margin-top: 10px;
  padding: 13px;
}

.review-sub,
.reviews {
  margin-bottom: 20px;
}
.reviews ul {
  padding-left: 0px;
  margin: 0;
  font-size: 12px;
}
.reviews .alert-info {
  margin-bottom: 0px;
}

.accordion-button.review-accord {
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  position: relative;
  background: #ce202f;
  color: #fff;
}

.accordion-body.review-body {
  padding: 10px;
}
.review-accord.accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' fill='white' class='bi bi-caret-up-fill' viewBox='0 0 16 16'%3E%3Cpath d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/%3E%3C/svg%3E");
  transition: all 0.5s;
  transform: rotate(-180deg);
  margin-top: -5px;
}
.review-accord.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' fill='white' class='bi bi-caret-up-fill' viewBox='0 0 16 16'%3E%3Cpath d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/%3E%3C/svg%3E");
  transform: rotate(0deg);
  margin-top: -4px;
}
.review-accord.accordion-button::after {
  transition: all 0.5s;
}

.certi-detail {
  /* background: url("/public/assets/images/bg-new.jpg") no-repeat center center
    fixed; */
  background: linear-gradient(
    180deg,
    #ca1f2e1a 0,
    #fff 17%,
    #fff 52%,
    #fff 83%,
    #ca1f2e1a
  );
  background-size: cover !important;
  font-size: 14px;
  color: #716e6e;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400;
  line-height: 22px;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  min-height: 100vh;
  padding-bottom: 100px;
  position: relative;
}

.certi-detail .container {
  max-width: 1170px;
}
.certi-detail .logo {
  padding: 0px 0;
  text-align: center;
}
.certi-detail .logo.mb-0 img {
  width: 100%;
  margin: 0px auto;
  display: block;
  filter: drop-shadow(2px 4px 6px #ca1f2e1a);
}
.certi-detail .outer-wrapper {
  background: none !important;
  padding: 0px !important;
}
.certi-detail .outer-wrapper .info-sec {
  /* background: #bdd6e6; */
  background: #ca1f2e0f;
  padding: 25px;
  min-height: 214px;
  border-top: 5px solid #ce202f;
  border-bottom: 5px solid #ce202f;
}
.certi-detail .outer-wrapper .info-sec h2.cert-head {
  color: #ce202f;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700;
  font-size: 18px;
}
.certi-detail .outer-wrapper .info-sec h4.underline {
  border-top: 1px solid #b6b1b1;
  border-bottom: 1px solid #b6b1b1;
  padding-top: 15px;
  font-size: 13px;
  font-family: "Roboto", sans-serif !important;
  color: #000000;
  font-weight: 700;
  padding-bottom: 15px;
}
.certi-detail .outer-wrapper .info-sec a.btn.btn-md.btn-primary {
  position: static;
  outline: none;
  height: 46px;
  padding: 12px;
  width: 118px;
  background: #ce202f;
  border: 1px solid #ce202f;
  color: #fff;
  font-weight: 500;
  float: right;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.certi-detail .outer-wrapper .info-sec a.btn.btn-md.btn-primary:hover {
  background: #3e3934 !important;
  border: 1px solid #3e3934 !important;
  color: #fff !important;
  text-decoration: none !important;
}

.certi-detail .outer-wrapper .info-sec .alignment {
  color: #000;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 500;
}
.certi-detail .outer-wrapper .info-sec label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
.certi-detail .outer-wrapper .info-sec h2.underline {
  border-top: 1px solid #b6b1b1;
  border-bottom: 1px solid #b6b1b1;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700;
  font-size: 18px;
  color: #4d4f53;
}
.certi-detail .outer-wrapper .info-sec .check-boexs {
  margin-right: 15px;
  float: left;
  margin-bottom: 20px;
  width: 21.3% !important;
  background: #fff;
  word-break: break-word;
}

.certi-detail .outer-wrapper .info-sec .imgcolor {
  margin-top: 10px;
  box-shadow: 0 10px 28px rgb(0 0 0 / 0%), 0 10px 10px rgb(0 0 0 / 11%);
  height: 130px;
  width: 100%;
  border-radius: 6px;
  margin-right: 8px;

  object-fit: contain;
}

.option {
  position: relative;
}
.option input {
  opacity: 0;
  position: absolute;
}

.option .btn-option {
  margin: 0 10px 10px 0;
  display: flex;
  background-color: transparent;
  border-color: #ce202f66;
}
.option .btn-option:hover,
.option .btn-option:focus,
.option .btn-option:active {
  background-color: transparent;
}

.option input:checked + span,
.option input:checked + span:hover,
.option input:checked + span:focus,
.option input:checked + span:active {
  background-color: #ce202f;
  color: #fff;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .img-bg .voucher-entery .form-check {
    margin-top: 20px;
  }
  .img-bg .voucher-entery .infor {
    margin-top: 10px;
  }
  .img-bg .read-head {
    padding-left: 0px;
  }
  .img-bg .header-outer img {
    display: inline-block;
    width: 290px;
    margin-top: -30px;
    margin-bottom: -30px;
  }
  .img-bg .header-outer .navbar-nav > li > a {
    padding: 31px 12px !important;
  }
  .cart,
  .points,
  .welcome {
    position: relative;
    display: block;
    padding-left: 42px;
  }
  .product-img img {
    max-height: 250px;
    max-width: 250px;
  }
  .product-img {
    height: 250px;
  }
  .img-bg .dropdown-menu > li > a {
    font-size: 14px;
  }
  .certi-detail .logo.mb-0 img {
    margin: 10px auto;
  }
  .img-bg img.img-fluid.home-logo {
    width: 100%;
    margin-bottom: 0;
    padding: 0px 9px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .img-bg .logo-sec,
  .img-bg .form-sec {
    width: 100%;
    float: none;
  }
  .img-bg img.img-fluid.home-logo {
    margin-top: 5px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }
  .img-bg .container.home-new {
    height: auto;
    padding-bottom: 30px;
  }
  .img-bg .read-head {
    padding-left: 0;
  }
  .img-bg .header-outer {
    padding-top: 15px;
  }
  .img-bg .header-outer .navbar {
    border: 1px solid #ccc;
    margin-bottom: 15px;
  }
  .img-bg .user-info {
    padding: 12px 0;
  }
  .img-bg .header-outer img {
    width: 230px;
    margin-left: 9px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .img-bg .header-outer .navbar-toggler {
    padding: 3px 7px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 15px;
  }
  .img-bg .header-outer .navbar-toggler:focus {
    background-color: #ddd;
  }
  .img-bg .header-outer .navbar-toggler .navbar-toggler-icon {
    width: 25px;
    height: 25px;
  }
  .img-bg .header-outer .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    padding-left: 15px;
    border-top: 1px solid #ccc;
  }
  .img-bg .header-outer .navbar-nav > li > a {
    padding: 15px 27px !important;
    color: #909090;
  }
  .img-bg .container {
    max-width: 750px;
  }

  .img-bg .dropdown-menu > li > a {
    font-size: 14px;
  }
  .img-bg .l-search input[type="text"],
  .img-bg .l-search select,
  .img-bg .login-inner input,
  .img-bg .user-form input,
  .img-bg .user-form select {
    font-size: 15px;
  }
  .img-bg .navbar-nav > li > .dropdown-menu {
    padding: 5px 0;
  }
  .certi-detail .logo.mb-0 img {
    width: 100%;
    margin: 0px auto;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .img-bg .logo-sec,
  .img-bg .form-sec {
    width: 100%;
    float: none;
    padding: 20px;
  }
  .img-bg img.img-fluid.home-logo {
    margin-top: 5px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }
  .img-bg .container.home-new {
    height: auto;
    padding-bottom: 30px;
  }
  .img-bg .read-head {
    padding-left: 0;
    font-size: 16px;
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  .img-bg .white-sec {
    margin-left: -20px;
    width: calc(100% + 23px);
  }
  .img-bg .instruction-list li,
  .img-bg .white-sec span,
  .img-bg .voucher-entery .form-check label,
  .img-bg .copyright-text {
    font-size: 14px;
  }
  .img-bg .instruction-list li img {
    width: 40px;
    margin-right: 15px;
  }
  .img-bg .voucher-entery label {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .img-bg .white-sec img {
    width: 50px;
    margin-right: 15px;
  }
  .img-bg .voucher-entery input.form-control {
    height: 45px;
    font-size: 15px;
  }
  .img-bg .voucher-entery .infor {
    margin-top: 10px;
    font-size: 14px;
  }
  .img-bg .voucher-entery {
    margin-top: 20px;
  }
  .img-bg .voucher-entery .form-check {
    margin-top: 15px;
    margin-bottom: 12px;
  }
  .img-bg .voucher-entery button.btn {
    height: 45px;
    width: 90px;
    font-size: 14px;
    margin-left: 7px;
  }
  .img-bg .g-recaptcha {
    width: 100%;
    overflow: auto hidden;
  }

  .img-bg .header-outer {
    padding-top: 15px;
  }
  .img-bg .header-outer .navbar {
    border: 1px solid #ccc;
    margin-bottom: 15px;
  }
  .img-bg .user-info {
    padding: 12px 0;
  }
  .img-bg .header-outer img {
    width: 200px;
    margin-left: 9px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .img-bg .header-outer .navbar-toggler {
    padding: 1px 5px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 10px;
  }
  .img-bg .header-outer .navbar-toggler:focus {
    background-color: #ddd;
  }
  .img-bg .header-outer .navbar-toggler .navbar-toggler-icon {
    width: 20px;
    height: 20px;
    vertical-align: bottom;
  }
  .img-bg .header-outer .navbar-expand-lg .navbar-nav {
    padding-left: 0px;
    border-top: 1px solid #efefef;
  }
  .img-bg .header-outer .navbar-nav > li > a {
    padding: 4px 15px !important;
    color: #909090;
    border: 1px solid #efefef;
    border-width: 1px 0px 1px 0px;
    font-size: 13px;
  }
  .img-bg .header-outer .navbar-nav > li:first-child > a {
    padding-left: 15px !important;
  }
  .img-bg .header-outer .navbar-nav > li > a.dropdown-toggle::after {
    margin-left: 0;
    float: right;
    margin-right: 0;
    margin-top: 8px;
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
  }

  .img-bg .container {
    max-width: 750px;
  }

  .img-bg .dropdown-menu > li > a {
    line-height: 15px;
    font-size: 12px;
    padding: 5px 15px 5px 25px !important;
    border: 1px solid #f9f9f9 !important;
    border-width: 1px 0px 1px 0px !important;
    padding-left: 14px !important;
    color: #5a5a5a !important;
  }
  .img-bg .l-search input[type="text"],
  .img-bg .l-search select,
  .img-bg .login-inner input,
  .img-bg .user-form input,
  .img-bg .user-form select {
    font-size: 15px;
  }
  .img-bg .header-outer .navbar-expand-lg .navbar-nav .dropdown-menu {
    padding: 0;
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .main-content {
    padding: 10px 0 0;
  }
  .l-search {
    background: #fff;
    margin-bottom: 11px;
  }
  .l-search h3,
  .user-form h3 {
    margin-bottom: 5px;
    font-size: 16px;
    padding: 9.5px;
  }
  .s-out {
    padding: 0px;
  }
  .l-search input[type="text"],
  .img-bg .l-search select {
    height: 30px;
    padding: 0px 10px;
  }
  .l-search input[type="submit"],
  .l-search input[type="reset"] {
    width: 18%;
    height: 30px;
    padding: 0px;
    border-radius: 0px;
    box-shadow: none;
    font-size: 11px !important;
  }
  .new-heading h1 {
    font-size: 17px;
    margin-bottom: 10px;
  }
  .new-heading h1 img {
    height: 16px;
    width: 16px;
    margin-right: 9px;
  }
  .cont-text {
    margin-bottom: 11px;
    padding: 4px 10px;
    font-size: 12px;
  }
  .product-wrap {
    margin-bottom: 10px;
  }
  .product-img {
    position: relative;
    height: 200px;
    overflow: hidden;
  }
  .product-img img {
    max-height: 80%;
    max-width: 80%;
    margin-bottom: 30px;
    margin-top: 5px;
  }
  .controls a,
  .controls button {
    width: 50%;
    border-right: 1px solid #fff;
    height: 29px;
    float: left;
    font-size: 13px;

    margin: 0px 0px;
  }
  .price {
    margin-top: 0px;
  }
  .product-name {
    margin: 10px 10px;
  }
  .reward-body {
    padding-bottom: 120px;
  }
  .back-2-top {
    bottom: 90px;
    z-index: 9999;
  }
  footer.login-footer p {
    margin-bottom: 0px;
    font-size: 12px;
    padding-bottom: 27px;
  }
  .ul-outer {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 11;
    top: 100%;
    margin-top: -35px;
  }
  ul.ul-inner {
    width: 100%;
    padding-left: 0px;
  }
  ul.ul-inner li {
    width: 33.33%;
    float: left;
    list-style: none;
  }
  ul.ul-inner li a,
  ul.ul-inner li button {
    color: #fff;
    width: 100% !important;
    height: auto !important;
    position: initial !important;
    transition: ease-in-out 0.5s;
    font-size: 15px;
    border: 0;
    outline: 0;
  }
  ul.ul-inner li:nth-child(1) a {
    background: #000000;
    text-align: center;
    padding: 7px 0px;
    display: block;
  }
  ul.ul-inner li:nth-child(1) i {
    margin-left: 0px;
  }
  ul.ul-inner li:nth-child(2) button {
    background: #3e3934;
    text-align: center;
    padding: 7px 0px;
    display: block;
  }
  ul.ul-inner li:nth-child(2) i {
    margin-left: 0px;
  }
  ul.ul-inner li:nth-child(3) button {
    text-align: center;
    background: #ce202f;
    padding: 7px 0px;
    display: block;
  }
  ul.ul-inner li:nth-child(3) i {
    margin-left: 0px;
  }
  .used-model {
    text-align: center;
    padding: 0 !important;
  }
  .used-model .modal-content {
    border-radius: 0;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, 0.2);

    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  }
  div#userinfo .modal-header {
    background: #ce202f !important;
    padding: 9px 15px;
    border-radius: 0;
  }

  div#userinfo .modal-header .close {
    margin-top: -2px !important;
    background: transparent;
    border: 0;
    outline: none;
    font-size: 22px;
    margin-top: 3px;
    color: #fff;
    opacity: 0.8;
  }
  div#userinfo h4 {
    color: #fff;
    font-size: 16px;
  }
  div#userinfo .modal-dialog {
    width: 70%;
    text-align: center;
    margin: 0px auto;
  }
  div#userinfo .circle {
    height: 35px;
    width: 35px;
    display: inline-block;
    background: #ce202f;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    padding: 8px 0px;
    font-size: 17px;
    margin: 0 15px;
    transition: ease-in-out 0.3s;
    -webkit-transition: ease-in-out 0.3s;
    -moz-transition: ease-in-out 0.3s;
  }
  div#userinfo h6 {
    text-align: center;
    font-size: 15px;
    border-bottom: 1px solid #e2e2e2;
    padding: 7px 0px 8px 0px;
    width: 60%;
    margin: 0 auto;
  }
  div#userinfo p {
    padding-top: 5px;
  }
  div#userinfo .modal-body p,
  div#userinfo .modal-body ul {
    line-height: 1.6;
    margin-bottom: 10px;
  }
  .img-bg ul.pagination {
    width: 100%;
    overflow: auto;
  }
  .img-bg ul.pagination .page-link {
    padding: 1px 7px;
    font-size: 12px;
  }

  /* search filter for mobile */
  .filter-button {
    background: white;
    color: #3e3934;
    outline: none;
    border: 0;
    height: 25px;
    width: 25px;

    align-items: center;
    justify-content: center;
    font-size: 14px;
    vertical-align: middle;
    float: right;
    margin-top: -3px;
  }
  .l-search {
    background: #fff;
    margin-bottom: 20px;
    position: fixed;
    width: 300px;
    z-index: 111;
    right: -500%;
    top: 0;
    bottom: 0;
    height: 100vh;
    overflow: auto;
    padding: 0px;
    transition: ease-in-out 0.6s;
    -webkit-transition: ease-in-out 0.6s;
  }
  .l-search.show {
    right: 0;
    transition: ease-in-out 0.4s;
    -webkit-transition: ease-in-out 0.4s;
  }
  .l-search .row {
    margin: 0;
  }
  /* cart page responsiveness  */
  .img-bg .table-shop {
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 25px;
    margin-top: 15px;
  }
  .img-bg .table-shop,
  .img-bg .table-shop tbody,
  .img-bg .table-shop td,
  .img-bg .table-shop tr {
    display: block;
    border: 0;
    width: 100%;
  }
  .img-bg .table-shop > thead > tr > th {
    border: none;
    text-transform: uppercase;
    padding: 10px 10px;
    font-weight: normal;
    display: none;
  }
  .img-bg .table-shop > tbody > tr {
    margin-bottom: 20px;
  }
  .img-bg .table-shop > tbody > tr > td {
    border-width: 2px 1px 2px 1px !important;
    border-right: 0px;
    border-left: 0px;
    border: 1px solid #cacaca;
    border-top: 2px solid #e9e9e9;
    border-bottom: 2px solid #e9e9e9;
    padding: 10px;
    vertical-align: top;
    position: relative;
    padding-left: 48% !important;
    display: block;
    font-size: 12px;
  }
  .img-bg .table-shop td div.th_title {
    position: absolute;
    left: 20px;
    font-weight: bold;
    width: 20%;
    padding-top: 1px;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-size: 12px;
  }
  .img-bg td.agree.padd-zero,
  .img-bg .table-shop > tbody > tr > td.skleton-td {
    padding-left: 20px !important;
  }
  .cont .button,
  .m-ship,
  .table-responsive {
    margin-bottom: 5px;
  }
  .img-bg .agree input {
    margin-right: 2px;
    margin-bottom: 2px;
  }
  .img-bg .desc-product h2 {
    border: 1px solid #ce202f;
    padding: 5px 10px;
    color: #fff;
    background: #ce202f;
    margin-top: 10px;
  }
  .img-bg .desc-text {
    height: auto;
    font-size: 13px;
    margin-top: 10px;
    min-height: auto;
    margin-bottom: 0px;
  }
  .img-bg .desc-text p {
    margin-bottom: 5px;
    color: #696969;
    font-size: 12px;
  }

  .certi-detail .logo.mb-0 img {
    width: 100%;
    margin: 0px auto;
  }
  .certi-detail .outer-wrapper .info-sec .check-boexs {
    margin-right: 0px;
    float: none;
    margin-bottom: 20px;
    width: 65% !important;
  }
}
