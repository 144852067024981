.contain {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    180deg,
    #d4efff 0,
    #fff 17%,
    #fff 52%,
    #fff 83%,
    #d4efff
  );
  background-image: linear-gradient(
    180deg,
    #d4efff 0,
    #fff 17%,
    #fff 52%,
    #fff 83%,
    #d4efff
  );
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
}
.contain .done {
  width: 70px;
  height: 70px;
  position: relative;
  left: 0;
  right: 0;
  margin: 5px auto;
  margin-top: 50px;
}
.contain .done img {
  width: 100%;
}
.sear-name {
  color: #ce202f;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
}
.order-done {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}
.contain .congrats label.form-check-label.form-check-label-with-text.mb-0 span {
  color: #ce202f;

  margin-bottom: 20px !important;
  margin-top: 5px;
}
.contain .congrats {
  position: relative;

  max-width: 500px;
  padding: 0px 10px;
  width: 80%;

  border-radius: 5px;

  background: #f3fbfe;
  text-align: center;
  font-size: 2em;
  color: #000;
}
.contain .text {
  position: relative;
  font-weight: normal;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  max-width: 800px;
  font-size: 0.6em;
}
